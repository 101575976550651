import React from 'react'
import Layout from '../../components/layout/Layout'
import AuthorProjectsSection from '../../sections/projects/fashion/AuthorProjectsSection'
import BrandProjectsSection from '../../sections/projects/fashion/BrandProjectsSection'

const Fashion = () => {
  return (
    <Layout pageName="fashion">
      <BrandProjectsSection />
      <AuthorProjectsSection />
    </Layout>
  )
}

export default Fashion
