import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { breakpoint } from '../../configs/utilities'
import { SectionTitle, SectionDescription } from '../common/typography/Typography'
import ProjectsCardComponent from '../common/cards/ProjectsCardComponent'

const ProjectsCardsSectionComponent = ({intl, ...props}) => {
  return (
    <Wrapper>
      <TextWrapper>
        <SectionTitle>{props.sectionTitle}</SectionTitle>
        <SectionDescription>{props.sectionSubTitle}</SectionDescription>
      </TextWrapper>
      <CardsWrapper>
        {props.cardData.map((item, index) => {
          return (
            <ProjectsCardComponent
              key={index}
              to={item.node.path}
              image={item.node.image.childImageSharp.gatsbyImageData}
              detail={item.node.detail.childImageSharp.gatsbyImageData}
              title={intl.formatMessage({ id: item.node.title })}
              subTitle={intl.formatMessage({ id: item.node.subTitle })}
              info01={intl.formatMessage({ id: item.node.info01 })}
              info02={intl.formatMessage({ id: item.node.info02 })}
            />
          )
        })}
      </CardsWrapper>
    </Wrapper>
  )
}

export default injectIntl(ProjectsCardsSectionComponent)


const Wrapper = styled.div``

const TextWrapper = styled.div``

const CardsWrapper = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 40px;
  flex-wrap: wrap;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    justify-content: flex-start;
    justify-content: space-between;
  }
  @media screen and (min-width: ${breakpoint.xxl}) {
    justify-content: flex-start;
    column-gap: 2.65%;
  }
`
