import React from 'react'
import styled from 'styled-components'
import { breakpoint, color, transition } from '../../../configs/utilities'
import { Link } from 'gatsby-plugin-react-intl'
import { GatsbyImage } from 'gatsby-plugin-image'

const ProjectsCardComponent = (props) => {
  return (
    <Wrapper>
      <Link to={props.to}>
        <ImageWrapper> {props.image && <GatsbyImage image={props.image} alt={props.title} />}</ImageWrapper>

        <ContentWrapper>
          <Detail>{props.detail && <GatsbyImage image={props.detail} alt={props.title} />}</Detail>

          <TextWrapper>
            <SubTitle>{props.subTitle}</SubTitle>
            <Title>{props.title}</Title>
          </TextWrapper>

          <InfoWrapper>
            <Info>{props.info01}</Info>
            <Info>{props.info02}</Info>
          </InfoWrapper>
        </ContentWrapper>
      </Link>
    </Wrapper>
  )
}

export default ProjectsCardComponent

const Wrapper = styled.div`
  a {
    color: ${color.black};
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: ${breakpoint.sm}) {
    width: 48%;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    width: 30%;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    width: 23%;
    transition: ${transition.default};
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    max-width: 330px;
  }

  @media (hover: hover) {
    &:hover {
       opacity: .8;
       transform: scale(1.005);
    }
  }
`

const ImageWrapper = styled.div``

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f9;
  padding: 20px 10px 10px;
  row-gap: 20px;
`

const Detail = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  text-align: center;
  margin-bottom: 20px;
  transition: ${transition.default};
`

const Title = styled.p`
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  min-height: 40px;
  max-width: 250px;
`
const SubTitle = styled.p`
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Info = styled.span`
  opacity: 0.6;
  font-size: 12px;
`
